.hero-carousel {
    /* ==== Main CSS === */
    .img-fill {
        width: 100%;
        display: block;
        overflow: hidden;
        position: relative;
        text-align: center;
    }

    .img-fill img {
        min-height: 100%;
        min-width: 100%;
        position: relative;
        display: inline-block;
        max-width: none;
    }

    *,
    *:before,
    *:after {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.04);
    }

    .Grid1k {
        padding: 0 15px;
        max-width: 1200px;
        margin: auto;
    }

    .blocks-box,
    .slick-slider {
        margin: 0;
        padding: 0 !important;
    }

    .slick-slide {
        float: left /* If RTL Make This Right */;
        padding: 0;
    }

    /* ==== Slider Style === */
    .slick-carousel.item .img-fill {
        height: 100vh;
        background: #000;
    }

    .slick-carousel.item .img-fill .info {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background: rgba(0, 0, 0, 0.5);
        line-height: 100vh;
        text-align: center;
    }

    .slick-carousel.item .img-fill img {
        filter: blur(5px);
    }

    .slick-carousel.item .info > div {
        display: inline-block !important;
        vertical-align: middle;
    }

    .slick-carousel.NextArrow {
        position: absolute;
        top: 50%;
        right: 0px;
        width: 45px;
        height: 45px;
        background: rgba(0, 0, 0, 0.5);
        border: 0 none;
        margin-top: -22.5px;
        text-align: center;
        font: 20px/45px FontAwesome;
        color: #fff;
        z-index: 5;
    }

    .slick-carousel.NextArrow:before {
        content: "\f105";
    }

    .slick-carousel.PrevArrow {
        position: absolute;
        top: 50%;
        left: 0px;
        width: 45px;
        height: 45px;
        background: rgba(0, 0, 0, 0.5);
        border: 0 none;
        margin-top: -22.5px;
        text-align: center;
        font: 20px/45px FontAwesome;
        color: #fff;
        z-index: 5;
    }

    .slick-carousel.PrevArrow:before {
        content: "\f104";
    }

    .slick-carousel.slick-dots {
        position: absolute;
        height: 5px;
        background: rgba(255, 255, 255, 0.2);
        bottom: 0px;
        width: 100%;
        left: 0px;
        padding: 0px;
        margin: 0px;
        list-style-type: none;
    }
    .slick-carousel.slick-dots li button {
        display: none;
    }
    .slick-carousel.slick-dots li {
        float: left;
        width: 0px;
        height: 5px;
        background: #d62828;
        position: absolute;
        left: 0px;
        bottom: 0px;
    }

    .slick-carousel.slick-dots li.slick-active {
        width: 100%;
        animation: ProgressDots 11s both;
    }

    .slick-carousel.item h3 {
        font: 30px/50px RalewayB;
        text-transform: uppercase;
        color: #fff;
        animation: fadeOutRight 1s both;
        margin: 0;
        padding: 0;
    }

    .slick-carousel.item h5 {
        margin: 0;
        padding: 0;
        font: 15px/30px RalewayR;
        color: #fff;
        max-width: 600px;
        overflow: hidden;
        height: 60px;
        animation: fadeOutLeft 1s both;
    }

    .slick-carousel.item.slick-active h3 {
        animation: fadeInDown 1s both 1s;
    }

    .slick-carousel.item.slick-active h5 {
        animation: fadeInLeft 1s both 1.5s;
    }

    .slick-carousel.item.slick-active {
        animation: Slick-FastSwipeIn 1s both;
    }

    .slick-carousel {
        background: #000;
    }

    /* ==== Slider Image Transition === */
    @keyframes Slick-FastSwipeIn {
        0% {
            transform: rotate3d(0, 1, 0, 150deg) scale(0) perspective(400px);
        }
        100% {
            transform: rotate3d(0, 1, 0, 0deg) scale(1) perspective(400px);
        }
    }

    @-webkit-keyframes ProgressDots {
        from {
            width: 0px;
        }
        to {
            width: 100%;
        }
    }
    @keyframes ProgressDots {
        from {
            width: 0px;
        }
        to {
            width: 100%;
        }
    }

    /* ==== Slick Slider Css Ruls === */
    .slick-slider {
        position: relative;
        display: block;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
    }
    .slick-list {
        position: relative;
        display: block;
        overflow: hidden;
        margin: 0;
        padding: 0;
    }
    .slick-list:focus {
        outline: none;
    }
    .slick-list.dragging {
        cursor: hand;
    }
    .slick-slider .slick-track,
    .slick-slider .slick-list {
        -webkit-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    .slick-track {
        position: relative;
        top: 0;
        left: 0;
        display: block;
    }
    .slick-track:before,
    .slick-track:after {
        display: table;
        content: "";
    }
    .slick-track:after {
        clear: both;
    }
    .slick-loading .slick-track {
        visibility: hidden;
    }
    .slick-slide {
        display: none;
        float: left /* If RTL Make This Right */;
        height: 100%;
        min-height: 1px;
    }
    .slick-slide.dragging img {
        pointer-events: none;
    }
    .slick-initialized .slick-slide {
        display: block;
    }
    .slick-loading .slick-slide {
        visibility: hidden;
    }
    .slick-vertical .slick-slide {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
