// -----------------------------------------------------------------------------
// This file contains all styles related to the Blockquote component.
// -----------------------------------------------------------------------------

/* ==========================================================================
   Pagination
   ========================================================================== */

.pagination {
  display: inline-block;
  margin: 30px 0;
  &-area {
    text-align: center;
  }
}

.pagination a {
  color: black;
  float: left;
  padding: 6px 15px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #eee;
  margin: 0 4px;
}

.pagination a.active {
  background-color: $primary;
  color: white;
  border: 1px solid $primary;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}
