// -----------------------------------------------------------------------------
// Contact page
// -----------------------------------------------------------------------------
.wrap__contact-form {
  display: block;
  padding-top: 0;
  h5 {
    text-transform: capitalize;
    margin-bottom: 30px;
  }
  .form-group {
    label {
      font-family: $text-font-paragraph;
      font-weight: 600;
    }
    input {
      font-family: $text-font-paragraph;
      text-transform: capitalize;
    }
  }

  .wrap__contact-form-office {
    display: block;
    .list-unstyled {
      li {
        display: flex;
        margin-bottom: 15px;
        font-family: $text-font-paragraph;
        font-weight: 500;
        span {
          display: block;
          line-height: 35px;

          i.fa {
            float: left;
            border: 1px solid #dbe1e8;
            width: 40px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            font-size: 18px;
            margin-right: 15px;
          }
          a {
            color: $black;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
