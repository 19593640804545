/* ==========================================================================
   Title Head
   ========================================================================== */

.title-head {
  h3 {
    text-transform: capitalize;
    display: flex;
    flex-direction: row;
    justify-content: center;
    @include mobile {
      font-size: 18px;
    }
  }
  h3:before,
  h3:after {
    flex-grow: 1;
    height: 1px;
    content: "\a0";
    background-color: #ddd;
    position: relative;
    top: 0.5em;
  }
  h3:before {
    margin-right: 15px;
  }
  h3:after {
    margin-left: 15px;
  }
}
