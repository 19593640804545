// -----------------------------------------------------------------------------
// Gallery component
// -----------------------------------------------------------------------------
.wrap__gallery {
  display: flex;
  .list-inline {
    .list-inline-item {
      width: 25%;
      float: left;
      position: relative;
      padding: 2px;
      margin: 0;
      a {
        display: block;
        position: relative;
        overflow: hidden;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          opacity: 0;
          background: linear-gradient(285deg, #f92a28bf 27%, #da1752c2 100%, #fff 100%);
          transition: all 0.5s ease-in-out;
          z-index: 1;
        }
        img {
          max-width: 100%;
          width: 100%;
          height: 85px;
          position: relative;
          object-fit: cover;
        }
        .icon_insta {
          color: #fff;
          font-size: 16px;
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          opacity: 0;
          text-align: center;
          -moz-transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          transition: all 0.5s ease-in-out;
          z-index: 2;
        }
      }
    }
  }
}
.wrap__gallery .list-inline .list-inline-item:hover a::before,
.wrap__gallery .list-inline .list-inline-item:hover .icon_insta {
  opacity: 1;
}
