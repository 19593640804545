/* ==========================================================================
   WIDGET ARCHIVE ARTICLE
   ========================================================================== */
.wrap__search-result {
  &-keyword {
    padding: 10px 15px;
    text-align: center;

    h5 {
      margin-bottom: 0;
    }
  }
}

.widget__form-search-bar {
  @include mobile {
    padding: 10px 20px 5px 5px;
  }
  @include tablet {
    width: 100%;
  }
  input {
    box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.05) !important;
  }
}

.modal-header {
  @include mobile {
    border-bottom: 0;
  }
}
