/*===================================================================================*/
/*	GENERAL
/*===================================================================================*/

.no-margin {
  padding: 0;
  margin: 0;
}

.no-padding {
  padding: 0;
}

.no-padding-right {
  padding-right: 0;
}

.no-padding-left {
  padding-left: 0;
}

.no-margin-left {
  margin-left: 0;
  padding-left: 0;
}

.no-margin-right {
  margin-right: 0;
  padding-right: 0;
}

.no-padding-bottom {
  padding-bottom: 0;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-30 {
  margin-top: 30px;
}


.pb-80 {
  padding-bottom: 80px;
  @media screen and (min-width: 320px) and (max-width: 575px) {
    padding-bottom: 40px;
  }
}

.pb-60 {
  padding-bottom: 60px;
  @media screen and (min-width: 320px) and (max-width: 575px) {
    padding-bottom: 40px;
  }
}

section {
  padding: 30px 0;
  @media screen and (min-width: 320px) and (max-width: 575px) {
    padding: 30px 0;
  }
  @media screen and (min-width: 576px) and (max-width: 768px) {
    padding: 40px 0;
  }
}

.bg-top-stories {
  background-color: #f1f4f7;
}

.border-tranding {
  border-bottom: 1px solid #eee;
}
a {
  transition: 0.3s ease;
}

ul {
  margin: 0;
  padding: 0;
}

a {
  outline: none !important;
}

a:hover,
a:active,
a:focus {
  text-decoration: none;
}

.text-dark {
  color: $black;
}

img{
  max-width: 100%;
  height: auto;
  object-fit: cover;
  vertical-align: middle;
  position: relative;
}

.bg-news-image {
  background-image: url("../../images/placeholder/1920x960.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
}

.bg-travel-image-center {
  // background-color: #eee;
  background-image: url("../../images/placeholder/1920x960.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-travel-image-left {
  // background-color: #eee;
  background-image: url("../../images/placeholder/1920x960.jpg");
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: contain;
}

.bg-travel-image-right {
  // background-color: #eee;
  background-image: url("../../images/placeholder/1920x960.jpg");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 233px auto;
}

.bg-content,
.wrapper__bg-tranding {
  background: $white;
}

.bg__post-cover {
  position: absolute;
  background-image: -moz-linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(0, 0, 0, 0.9) 100%
  );
  background-image: -ms-linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.9) 100%);
  background-image: -o-linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.9) 100%);
  background-image: -webkit-linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(0, 0, 0, 0.9) 100%
  );
  background-image: -webkit-gradient(linear, center top, center bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.9)));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.9) 100%);
  &::before {
    &::before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      opacity: 0;
      pointer-events: none;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.3);
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
    }
  }
}

.bg__card-shadow {
  -ms-box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.06);
  -o-box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.06);
  box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.06);
}

.bg__card-shadow-two {
  -ms-box-shadow: 0 2px 40px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 2px 40px rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 40px rgba(0, 0, 0, 0.08);
}

.wrap__section {
  padding-top: 60px;
  padding-bottom: 60px;
  @media screen and (min-width: 320px) and (max-width: 575px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.popular__blog-top,
.popular__blog-diary {
  h3 {
    margin-bottom: 15px;
  }
  p {
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 15px;
  }
}

.slick-list {
  .slick-loading & {
    // background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    background: transparent !important;
    display: none;
  }
}
