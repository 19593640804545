// -----------------------------------------------------------------------------
// PROFILE AUTHOR
// -----------------------------------------------------------------------------
.wrap__profile {
  border: 1px solid #ddd;
  padding: 20px;
  position: relative;
  display: flex;
  margin-bottom: 50px;

  &-author {
  
    display: flex;
    @include mobile {
      display: block;
      text-align: center;
    }
    figure {
      width: 180px;
      margin-right: 15px;
      float: left;
      height: auto;
      display: block;
      position: relative;
      @include mobile {
        max-width: 100%;
        width: 100%;
        text-align: center;
      }
      img {
        max-width: 100%;
        width: 100%;
        height: auto;
        object-fit: cover;
        vertical-align: middle;
      }
    }
    &-detail {
    
      float: left;
      @include tablet {
        margin-left: 10px;
      }
      &-name {
      
        text-transform: uppercase;
        color: $gray-600;
        letter-spacing: 1px;
        font-family: $text-font-paragraph;
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 5px;
      }
      h4 {
        text-transform: capitalize;
        @include mobile {
          font-size: 18px;
        }
      }
      p {
      }
      .list-inline {
        margin: 0;
        .list-inline-item {
        
          .btn-social.telegram {
            background: #179cde;
            &:hover {
              background: transparent;
              color: #179cde;
            }
          }
        }
      }
    }
  }
}
