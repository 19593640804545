// -----------------------------------------------------------------------------
// Banner component
// -----------------------------------------------------------------------------
.hero__banner {
}

.slider-container {
	display: block;
	position: relative;

	.container-slider-image-full {
		width: 100%;
		// max-height: 680px;
		float: left;
		padding-right: 0;
		position: relative;
		background: #000;
		overflow: hidden;
		&:before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 30%;
			background: linear-gradient(transparent, #000000);
			z-index: 1;
		}
		img {
			width: 100%;
			max-width: 100%;
			//   height: 680px;
			//   max-height: 680px;
			object-fit: cover;
			// position: absolute;
			height: 748px;
			width: 100%;
			z-index: 1;
			opacity: 0.6;
			@include mobile {
				height: auto;
			}
			@include tablet {
				height: 350px;
			}
		}

		.carousel-caption {
			top: 40%;
			// max-width: 100%;
			margin: 0 auto;
			display: block;
			@include mobile {
				padding-top: 0;
				padding-bottom: 0;
				right: 10%;
				left: 10%;
			}
			@include tablet {
				top: 20%;
			}
			h1 {
				-ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=0, Color=#000000)"; /*IE 8*/
				text-shadow: 0 1px 4px #000000; /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
				filter: progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color=#000000); /*IE 5.5-7*/
				display: inline-block;
				padding: 8px 20px;
				font-size: 50px;
				line-height: 1.3;
				text-align: center;
				@include mobile {
					font-size: 18px;
					padding: 0;
				}
				@include tablet {
					font-size: 30px;
				}
			}
			p {
				font-size: 22px;
				@include mobile {
					font-size: 10px;
				}
			}
		}
	}
}

.carousel-fade .carousel-item {
	opacity: 0;
	transition: opacity ease-out 0.7s;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
	opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
	opacity: 0;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
	transform: translateX(0);
	transform: translate3d(0, 0, 0);
}

.carousel {
	.carousel-control-next,
	.carousel-control-prev {
		visibility: hidden;
		transition: all 300ms ease-out 50ms;
	}
	&:hover {
		.carousel-control-next,
		.carousel-control-prev {
			visibility: visible;
			transition: all 300ms ease-out 50ms;
		}
	}
}

.card__banner {
	box-sizing: border-box;
	border: 1px solid $gray-200;
	padding: 10px;
	border-radius: 4px;
	&-media {
		position: relative;
		display: block;
		overflow: hidden;
		cursor: pointer;

		img {
			object-fit: cover;
			position: relative;
			width: 100%;
			transition: all 0.8s ease;
			&:hover {
				-webkit-transform: scale(1.1);
				transform: scale(1.1);
				transition: all 0.8s ease-in-out;
			}
		}
	}
}

.card__banner__full {
	box-sizing: border-box;
	border-radius: 4px;
	&-media {
		// position: relative;
		display: block;
		overflow: hidden;
		cursor: pointer;

		img {
			object-fit: cover;
			position: relative;
			width: 100%;
			transition: all 0.8s ease;
			&:hover {
				-webkit-transform: scale(1.1);
				transform: scale(1.1);
				transition: all 0.8s ease-in-out;
			}
		}
	}
}

.card__banner-media-border {
	border: 1px solid $gray-200;
	padding: 10px;
	display: inline-block;
	img {
		position: relative;
		width: 100%;
	}
}

.card__banner__hover-border {
	position: relative;
	&:before {
		content: "";
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		transition: all 0.35s ease 0s;
	}
	&:hover {
		&:before {
			opacity: 1;
		}
		.card__banner__hover-media {
			&:after {
				opacity: 1;
				transform: scale(1);
				transition-delay: 0.15s;
			}
			&:before {
				opacity: 1;
				transform: scale(1);
				transition-delay: 0.15s;
			}
		}
	}
	img {
		width: 100%;
		height: auto;
	}
	.card__banner__hover-media {
		position: absolute;
		top: 10px;
		left: 10px;
		right: 10px;
		bottom: 10px;
		margin: 0;
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			opacity: 0;
			transition: all 0.7s ease 0s;
			border-left: 2px solid rgba(255, 255, 255, 0.5);
			border-right: 2px solid rgba(255, 255, 255, 0.5);
			transform: scale(1, 0);
			transform-origin: 100% 0 0;
		}
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			opacity: 0;
			transition: all 0.7s ease 0s;
			border-bottom: 2px solid rgba(255, 255, 255, 0.5);
			border-top: 2px solid rgba(255, 255, 255, 0.5);
			transform: scale(0, 1);
			transform-origin: 0 0 0;
		}
	}
}
