/* ==========================================================================
   WIDGET ARCHIVE ARTICLE
   ========================================================================== */

.h-150 {
  height: 150px;
  max-height: 100%;
  width: 100%;
  position: relative;
  object-fit: cover;
  @include mobile {
    height: auto;
  }
}

.h-360 {
  height: 360px;
  max-height: 100%;
  width: 100%;
  position: relative;
  object-fit: cover;
  @include mobile {
    height: auto;
  }
}
.h-240 {
  height: 240px;
  max-height: 100%;
  width: 100%;
  position: relative;
  object-fit: cover;
  @include mobile {
    height: auto;
  }
}

.h-270 {
  height: 270px;
  max-height: 100%;
  width: 100%;
  position: relative;
  object-fit: cover;
  @include mobile {
    height: auto;
  }
}
.h-540 {
  height: 540px;
  max-height: 100%;
  width: 100%;
  position: relative;
  object-fit: cover;
  @include mobile {
    height: auto;
  }
}
/* ==========================================================================
   WIDGET CATEGORY ARTICLE
   ========================================================================== */
.wrap__masonary {
}

.wrap__masonary-card {
  .card {
    position: relative;
    overflow: hidden;
    border: 0;
    .thumbnail-cover {
      position: relative;
      ::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        background: #000;
        z-index: 1;
        width: 100%;
        height: 100%;
        transition: 0.25s ease-out;
        opacity: 0.5;
        pointer-events: none;

        img {
          position: relative;
          object-fit: cover;
        }
      }
    }

    .masonary__category {
      position: absolute;
      z-index: 3;
      top: 15px;
      left: 15px;
      margin: 0;
      line-height: 1;
      padding: 5px 8px;
      span {
        a {
          text-transform: uppercase;
          font-family: $text-font-paragraph;
          font-size: 11px;
          line-height: 15px;
          color: $white;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    .masonary__title {
      position: absolute;
      bottom: 50%;
      -webkit-transform: translateY(50%);
      transform: translateY(50%);
      padding: 20px;
      z-index: 2;
      h4 {
        a {
          color: $white;
          &:hover {
            opacity: 0.9;
          }
        }
      }
      p {
        color: $white;
        font-size: 14px;
      }
    }

    .masonary__title-info-author {
      border-top: 1px solid hsla(0, 0%, 100%, 0.15);
      position: absolute;
      bottom: 1.5%;
      -webkit-transform: translateY(10%);
      transform: translateY(10%);
      padding: 5px 20px;
      z-index: 2;
      width: 100%;
      display: block;
      .list-inline {
        margin: 0;
        padding: 0;
        .list-inline-item {
          //   border: 1px solid gray;
          span {
            color: $white;
            font-family: $text-font-paragraph;
            font-size: 12px;
            font-weight: 600;
          }
        }
      }
    }

    .bg-masonary-category {
      background: $primary;
      color: $white;
    }
  }
}

.wrap__masonary-card {
  .card {
    .card-img-top {
      position: relative;
      overflow: hidden;
    }
    .card-body {
      .card-title {
        font-size: 18px;
        a {
          color: $black;
          &:hover {
            color: $primary;
            text-decoration: none;
            opacity: 0.9;
          }
        }
      }
      .card-text {
        font-size: 14px;
      }
    }

    .masonary__title-info-author-card {
      border-top: 1px solid #eee;
      .list-inline {
        padding: 0;
        margin: 0;
        .list-inline-item {
          span {
            color: $black;
            font-family: $text-font-paragraph;
            font-size: 12px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.wrap__masonary-card .card .thumbnail-cover img {
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.wrap__masonary-card .card .thumbnail-cover :hover img {
  transform: scale3d(1.1, 1.1, 1);
  -webkit-transform: scale3d(1.1, 1.1, 1);
  -moz-transform: scale3d(1.1, 1.1, 1) rotate(0.02deg);
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.wrap__masonary-card .card .card-img-top img {
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.wrap__masonary-card .card .card-img-top:hover img {
  transform: scale3d(1.1, 1.1, 1);
  -webkit-transform: scale3d(1.1, 1.1, 1);
  -moz-transform: scale3d(1.1, 1.1, 1) rotate(0.02deg);
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.masonary-cover-bg {
}

// .wrap__masonary {
//   display: none;
//   padding: 10px;
//   border-width: 0 1px 1px 0;
//   border-style: solid;
//   border-color: #fff;
//   box-shadow: 0 1px 1px #ccc;
//   margin-bottom: 5px;
//   background-color: #f1f1f1;
// }
