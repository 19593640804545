// -----------------------------------------------------------------------------
// Basic styles
// -----------------------------------------------------------------------------

// example:
// https://github.com/HugoGiraudel/sass-boilerplate/blob/master/stylesheets/base/_base.scss

html,
body {
  height: 100%;
}

.body-box {
  max-width: 1230px;
  height: auto;
  margin: 0 auto;
  position: relative;
}
