// -----------------------------------------------------------------------------
// Card Article component
// -----------------------------------------------------------------------------

.article__entry-carousel,
.article__entry-carousel-three {
    /* the slides */
    .slick-slide {
        margin-left: 27px;
        @include mobile {
            margin-left: 15px;
        }
    }
    /* the parent */
    .slick-list {
        margin-left: -27px;
        @include mobile {
            margin-left: -15px;
        }
    }
    .slick-arrow {
        opacity: 1;
        top: -62px;
        margin-top: 0;
        padding: 7px;
        width: 40px;
        height: 40px;
        line-height: 30px;
        text-align: center;
        border: 1px solid #e3e4e8;
        font-size: 12px;
        border-radius: 0;
        &:hover {
            color: $white;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }
    }

    .slick-prev {
        border: 1px solid #eee;
        left: auto;
        right: 39px;
        top: -54px;
    }
    .slick-next {
        border: 1px solid #eee;
        top: -54px;
        right: 0;
    }
}

.article__entry-carousel {
    .article__entry {
        .article__image {
            width: 100%;
            height: auto;
            img {
                height: 180px;
                max-width: 100%;
                -o-object-fit: cover;
                object-fit: cover;
                position: relative;
                @include mobile {
                    height: 120px;
                }
                @include tablet {
                    height: 140px;
                }
            }
        }
    }
    ul.list-inline {
        @include tablet {
            display: none;
        }
        li.list-inline-item {
            @include mobile {
                display: none;
            }
        }
    }
}
// -----------------------------------------------------------------------------
// Products component
// -----------------------------------------------------------------------------
.article__carousel__container {
    position: relative;
    display: block;
    .slick-prev,
    .slick-next {
        width: 28px;
        height: 30px;
        // display: inline-block;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 1;
        visibility: hidden;
        -webkit-transition: 0.5s ease;
        -o-transition: 0.5s ease;
        transition: 0.5s ease;
        &::before {
            color: $white;
            font-size: 14px;
        }
    }
}

.article__carousel__container:hover .slick-prev {
    visibility: visible;
    opacity: 1;
    left: 0;
    -webkit-transform: translateY(-50%) scale(1);
    -ms-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
}
.article__carousel__container:hover .slick-next {
    visibility: visible;
    opacity: 1;
    right: 0;
    -webkit-transform: translateY(-50%) scale(1);
    -ms-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
}

.bg__post-carousel-cover {
    position: relative;
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0, rgba(0, 0, 0, 0)),
        color-stop(50%, rgba(0, 0, 0, 0.4)),
        to(rgba(0, 0, 0, 0.9))
    );
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.9) 100%);
}

.item-slick.slick-slide.slick-current.slick-active {
    outline: none !important;
}

.slider-for {
    margin-bottom: 10px;
}

.slider-for img {
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    height: 450px;
    max-height: 100%;
}

.slider-nav {
    margin: auto;
}

.slider-nav .item-slick {
    max-width: 100%;
    height: 128px;
    margin-right: 10px;
    outline: none !important;
    cursor: pointer;
    object-fit: cover;
    // height: 10.5vh;
    @include mobile {
        margin-right: 10px;
    }
}

.slider-nav .item-slick img {
    max-width: 100%;
    height: auto;
    background-size: cover;
    background-position: center;
}

.slick-arrow {
    position: absolute;
    top: 50%;
    z-index: 50;
}

.slider-container {
    .slick-prev,
    .slick-next {
        display: inline-block;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: 0.5s ease;
        -o-transition: 0.5s ease;
        transition: 0.5s ease;
        &::before {
            color: $black;
            font-size: 15px;
        }
    }
}

.slider-nav {
    .slick-prev {
        @include mobile {
            display: none !important ;
        }
    }
    .slick-next {
        @include mobile {
            display: none !important;
        }
    }
}
.slider-container:hover .slick-prev {
    visibility: visible;
    opacity: 1;
    left: 0;
    -webkit-transform: translateY(-50%) scale(1);
    -ms-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
}
.slider-container:hover .slick-next {
    visibility: visible;
    opacity: 1;
    right: 0;
    -webkit-transform: translateY(-50%) scale(1);
    -ms-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
}

.gallery-slider {
    position: relative;
    overflow: hidden;
    background-color: gray;

    // __images
    &__images {
        margin: 0;
        position: relative;
        .item {
            padding: 0;
            .img-wrapper {
                text-align: center;
                padding: 10px;
                height: 200px;
                @media screen and (min-width: 576px) {
                    height: 300px;
                }
                @media screen and (min-width: 720px) {
                    height: 450px;
                }

                img {
                    display: inline-block;
                    position: relative;
                    max-width: 100%;
                    max-height: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        .next-arrow,
        .prev-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 47px;
            height: 47px;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.7);
            border: 0 none;
            text-align: center;
            color: #fff;
            z-index: 5;
            opacity: 0;
            transition: all 0.5s;
            outline: 0;
            svg {
                top: 4px;
            }
        }
        .next-arrow {
            right: 25px;
        }
        .prev-arrow {
            left: 25px;
        }
        &:hover .next-arrow,
        &:hover .prev-arrow {
            opacity: 1;
        }
        .caption {
            width: 100%;
            position: relative;
            text-align: center;
            display: block;
            opacity: 1;
            transition: opacity 0.15s;
            &.hide {
                opacity: 0;
            }
        }
    }

    // __thumbnails
    &__thumbnails {
        position: relative;
        top: auto;
        left: 0px;
        width: 100%;
        z-index: 4;
        transition: all 0.8s;
        margin: 0;
        padding: 13px 0;
        &:before,
        &:after {
            content: "";
            display: block;
            width: 100px;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: 10;
            pointer-events: none;
        }
        &:before {
            left: 0;
            // background: linear-gradient(to right, rgba(#gray, 1) 0%, rgba(#gray, 0) 100%);
        }
        &:after {
            right: 0;
            // background: linear-gradient(to right, rgba(#gray, 0) 0%, rgba(#gray, 1) 100%);
        }
        .item {
            .img-wrapper {
                height: 70px;
                background: white;
                cursor: pointer;
                border: 5px solid white;
                text-align: center;
                img {
                    opacity: 0.5;
                    transition: all 0.5s;

                    display: inline-block;
                    position: relative;
                    max-width: 100%;
                    max-height: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            &:hover .img-wrapper img {
                opacity: 1;
            }
            &.slick-center {
                img {
                    opacity: 1;
                }
            }
        }
        .next-arrow,
        .prev-arrow {
            position: absolute;
            width: 40px;
            height: 40px;
            top: 50%;
            transform: translateY(-50%);
            background: rgba(0, 0, 0, 0.6);
            color: #fff;
            border: 0 none;
            z-index: 15;
            svg {
                top: 2px;
            }
        }
        .next-arrow {
            right: 0px;
        }
        .prev-arrow {
            left: 0px;
        }
    }

    // generic arrows
    .next-arrow,
    .prev-arrow {
        cursor: pointer;
        svg {
            width: 18px;
            height: 18px;
            position: relative;
        }
    }

    // image fills
    .img-wrapper {
        width: 100%;
        display: block;
        overflow: hidden;
        position: relative;
        text-align: center;
    }

    // slick overrides
    .slick-slider {
        margin: 0 -7.5px;
        padding: 0 !important;
    }
    .slick-slide {
        float: left;
        padding: 0 7.5px;
        outline: none;
    }
}
