// -----------------------------------------------------------------------------
// Google Fonts
// https://fonts.google.com/
// -----------------------------------------------------------------------------

 $slick-font-path: "/node_modules/slick-carousel/slick/fonts/" !default;
 $slick-font-family: "slick" !default;
 $slick-loader-path: "/node_modules/slick-carousel/slick-carousel/slick/" !default;
 $slick-arrow-color: black !default;


@import "~slick-carousel/slick/slick.scss"; 
@import "~slick-carousel/slick/slick-theme.scss";

