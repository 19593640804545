// -----------------------------------------------------------------------------
// Slider component
// -----------------------------------------------------------------------------

/*===== Trending News =====*/

.trending-news {
  padding-bottom: 1.5rem;

  .trending-news-inner {
    position: relative;
    .title {
      float: left;
      padding: 10px 15px;

      background: -moz-linear-gradient(285deg, #f92a28 27%, #da1752 100%, #ffffff 100%); /* FF3.6+ */
      background: -webkit-gradient(
        linear,
        285deg,
        color-stop(27%, F92A28),
        color-stop(100%, DA1752),
        color-stop(100%, FFFFFF)
      ); /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(285deg, #f92a28 27%, #da1752 100%, #ffffff 100%); /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(285deg, #f92a28 27%, #da1752 100%, #ffffff 100%); /* Opera 11.10+ */
      background: -ms-linear-gradient(285deg, #f92a28 27%, #da1752 100%, #ffffff 100%); /* IE10+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1301FE', endColorstr='#F4F60C', GradientType='1'); /* for IE */
      background: linear-gradient(285deg, #f92a28 27%, #da1752 100%, #ffffff 100%); /* W3C */
      color: $white;
      text-transform: uppercase;
      font-size: 12px;

      strong {
        @include mobile {
          display: none;
        }
      }
    }
    .trending-news-slider {
      //   border: 1px solid #ddd;
      .item-single {
        height: auto;
        vertical-align: middle;
        display: table-cell;
        overflow: hidden;
        line-height: 20px;
        text-align: left;

        a {
          font-family: $text-font-paragraph;
          color: $black;
          font-weight: 600;
          line-height: 34px;
          padding: 2px 10px;
          display: inline-block;
          vertical-align: top;

          @include mobile {
            font-size: 12px;
            font-weight: 600;
            line-height: 1.4;
            padding: 4px 7px;
          }
          &:hover {
            color: $primary;
          }
        }
      }
      .slick-arrow {
        position: absolute;
        // display: none !important;
      }
      .slick-next,
      .slick-prev {
        cursor: pointer;
        display: inline-block;
        width: 30px;
        height: 30px;
        // line-height: 25px;
        border: 1px solid #eee;
        // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background: $white;
        overflow: hidden;
        transition: 0.4s;
        &::before {
          font-size: 14px;
        }
        &:hover {
          background: #f7f7f7;
        }
      }
      .slick-prev {
        left: auto;
        right: 29px;
        bottom: 0;
      }
      .slick-next {
        right: 0;
      }
    }
  }
}

.top__news__slider {
  /* the slides */
  .slick-slide {
    margin-left: 27px;
    img {
      height: 180px;
      max-width: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      position: relative;

      @include mobile {
        width: 115px;
        height: 100px;
        max-height: 100%;
        margin-right: 10px;
        float: left;
      }
    }
  }
  /* the parent */
  .slick-list {
    margin-left: -27px;
  }
}
