// -----------------------------------------------------------------------------
// Index page
// -----------------------------------------------------------------------------
.bg-overlay {
	background-color: rgba(239, 239, 239, 0.76);
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	right: 0;
}

.first {
	height: 100vh;
	min-height: 400px;
	background-size: cover;
	background-image: url("../../images/placeholder/1920x960.jpg");
	background-position: top center;
	background-attachment: fixed;
	.wrap-text {
		h1 {
			font-size: 3.571em;
			text-transform: uppercase;
			font-family: "Poppins", sans-serif;
			@include mobile {
				font-size: 1.571em;
				margin-bottom: 20px;
			}
		}
		h2 {
		}
		p {
			font-size: 22px;
			line-height: 1.7;
			font-weight: 600;
			text-transform: capitalize;
			@include mobile {
				font-size: 16px;
				margin-bottom: 1em;
			}
		}
	}
	figure {
		margin-bottom: 3rem;
		@include mobile {
			margin-bottom: 2rem;
		}
		img {
			text-align: center;
			position: relative;
			@include mobile {
				max-width: 70%;
				vertical-align: middle;
			}
		}
	}
}

.title-demo {
	display: flex;
	h2 {
		@include mobile {
			font-size: 22px;
		}
	}
	h3 {
		font-family: "Poppins", sans-serif;
		@include mobile {
			font-size: 22px;
		}
	}
	p {
		font-size: 18px;
		line-height: 1.7;
		margin-bottom: 0;
		@include mobile {
			font-size: 14px;
		}
	}
}
#demo {
	scroll-behavior: smooth;
}
.preview__image {
	padding-left: 100px;
	padding-right: 100px;
	@include mobile {
		padding-left: 0;
		padding-right: 0;
	}
	@include tablet {
		padding-left: 20px;
		padding-right: 20px;
	}
	.demo-title {
		margin-bottom: 30px;
		margin-top: 30px;
		h4 {
			text-transform: uppercase;
			font-size: 18px;
			font-weight: 700;
		}
	}
}

.preview__image-thumb {
	position: relative;
	margin-bottom: 20px;
	img {
		-webkit-transition: all 0.3s ease;
		-moz-transition: all 0.3s ease;
		-ms-transition: all 0.3s ease;
		-o-transition: all 0.3s ease;
		transition: all 0.3s ease;
		-webkit-box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
		-moz-box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
		box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
		-webkit-border-radius: 10px 10px 0 0;
		-moz-border-radius: 10px 10px 0 0;
		-ms-border-radius: 10px 10px 0 0;
	}
	&:hover {
		img {
			// -moz-transform: translateY(-30px);
			// -webkit-transform: translateY(-30px);
			// -o-transform: translateY(-30px);
			// -ms-transform: translateY(-30px);
			// transform: translateY(-30px);
			-webkit-box-shadow: 0px 5px 50px rgba(black, 0.4);
			-moz-box-shadow: 0px 5px 50px rgba(black, 0.4);
			box-shadow: 0px 5px 50px rgba(black, 0.4);
		}
	}
	span {
		-webkit-transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
		transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
		position: absolute;
		bottom: 0px;
		left: 0;
		width: 100%;
		visibility: hidden;
		opacity: 0;
		-webkit-transform: translateY(100%);
		-ms-transform: translateY(100%);
		transform: translateY(100%);
		padding: 25px 30px;
		background: $primary;
		color: #fff;
		letter-spacing: 2px;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 700;
		text-align: center;
	}
}

.preview__image-thumb:hover span {
	visibility: visible;
	opacity: 1;
	-webkit-transform: translateY(0%);
	-ms-transform: translateY(0%);
	transform: translateY(0%);
}

.footer-area {
	text-align: center;
	background-color: $black;
	padding-top: 240px;
	padding-bottom: 100px;
	background-image: url("../../images/placeholder/1920x960.jpg");
	background-position: center center;
	background-size: cover;
	@include mobile {
		padding-top: 30px;
	}
	h1 {
		text-align: center;
		text-transform: uppercase;
		font-family: "Poppins", sans-serif;
		@include mobile {
			font-size: 26px;
		}
	}
}
/* ==========================================================================
   Demo Preview
   ========================================================================== */
