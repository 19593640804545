// -----------------------------------------------------------------------------
// ABOUT US PAGE
// -----------------------------------------------------------------------------
.wrap__about-us {
  h2 {
    margin: 30px 0 20px;
    @include mobile {
      font-size: 22px;
    }
  }
  h4 {
    color: $gray-800;
    line-height: 24px;
    @include mobile {
      font-size: 18px;
    }
  }
  p {
    font-size: 16px;
    line-height: 28px;
    font-family: $text-font-paragraph;
  }
}

// -----------------------------------------------------------------------------
// TEAM
// -----------------------------------------------------------------------------
.team-member {
  .member {
    img {
      position: relative;
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
    }
    figcaption {
      width: 100%;
      border: 1px solid #eee;
      padding: 15px 10px;
      text-align: center;
      background-color: #fff;
      border-radius: 3px;
      h4 {
        font-size: 16px;
      }
      small {
        font-family: $text-font-paragraph;
      }
      ul.list-inline {
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
        li.list-inline-item {
          margin: 0;
          margin-right: 0px;
          margin-right: 10px;
          padding: 0;
          list-style: none;
          a {
            float: left;
            color: #fff;
            font-size: 13px;
            height: 30px;
            width: 30px;
            background-color: $primary;
            padding: 5px;
            &:hover {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}
